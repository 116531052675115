* {
  margin: 0;
  padding: 0;
}

.scall-container {
  overflow: hidden;
  overflow-y: scroll;
}

.bl-container {
  width: 70%;
  height: 80%;
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  /* flex-wrap: wrap; */
}

.bl-container .close-btn {
  display: flex;
  width: 100%;

  justify-content: center;
}

.bl-container .close-btn .bl-cancel {
  width: 130px;
  margin-bottom: 10px;
  display: inline-block;
  border: 1px solid rgb(204, 199, 199);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: rgb(126, 122, 122);
}

.bl-container .close-btn .bl-save {
  width: 130px;
  margin-bottom: 10px;
  display: inline-block;
  border: none;
  align-items: center;
  justify-content: end;
  border-radius: 5px;
  margin-right: 200px;
  color: #fff;
  background-color: #7ac142;
}

.bl-container .close-btn button {
  padding: 8px;
  border: none;
  justify-content: end;
}

/* .bl-User {
  width: 100%;
  height: 90%;
  display: flex;

  justify-content: center;
  border: 1px solid black;
}
.bl-userdetail {
  width: 500px;
  height: 300px;
  background-color: aqua;
  display: flex;
} */
