.complete {
  background-color: #7ac142;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}

.other {
  background-color: #f7d511;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}

.bl-back {
  margin-top: 20px;
  padding: 3px;
  width: 60px;
}
