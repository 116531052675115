* {
  margin: 0;
  padding: 0;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* filter: blur(4px); */
  backdrop-filter: blur(7px);
}

.container {
  max-width: 600px;
  width: 100%;
  height: 50%;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* animation */
  animation: drop 0.5s case forwards;
}

@keyframes drop {
  0% {
    opacity: 0;
  }
  70% {
    transform: translateY(700px);
  }
  100% {
    transform: translateY(650px);
    opacity: 1;
  }
}

.container img {
  width: 6rem;
  height: 6rem;
}

.container .btn {
  margin-top: 10px;
}

.container button {
  margin-top: 30px;
  padding: 8px;
  border-radius: 5px;
}

.container .btn .submit {
  border: 1px solid blue;
  color: blue;
}

.container .btn .cancel {
  width: 80px;
  border: 1px solid rgb(168, 159, 159);
  color: rgb(77, 70, 70);
}
