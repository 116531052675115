.Bag-icon {
  display: flex;
  flex-wrap: wrap;
}
.bag {
  cursor: pointer;
}

.NumberBook {
  position: absolute;
  background-color: orangered;
  color: #fff;
  padding: 4px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
  z-index: 99;
}
.text1 span {
  color: red;
}
